import React from "react";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import { Select } from "../../../../../../../../shared/Select/Select";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";

const PaymentFormModal = ({
  form,
  changeFormValue,
  cardOptions,
  isValid,
  showTipsModal,
  applyTip,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { invoice } = useInvoice();

  const handleSubmit = () => {
    showTipsModal.update(true);
    if (invoice.status === "draft") {
      applyTip.initiate({
        tipAmount: 0,
        readerId: form.selectedCard.readerIdentifier,
      });
    }
  };

  return (
    <>
      <div className="col-sm-12 p-b-10">
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.cardReader")}
        </InputLabel>
        <Select
          size="medium"
          value={form.selectedCard}
          onChange={(option) => changeFormValue("selectedCard", option)}
          options={cardOptions || []}
        />
      </div>
      <ModalCharge.Footer
        onClose={paymentViewClose}
        onCharge={handleSubmit}
        isChargeDisabled={!isValid}
        chargeLabel={tCommon("label.continue")}
      />
    </>
  );
};

export default PaymentFormModal;
